import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import Image from '#/components/shared/ui/Image';
import MotionContainer from '#/components/shared/animate/MotionContainer';
import { varBounce } from '#/components/shared/animate/variants';
import Page from '#/components/shared/ui/Page';

export default function Page404() {
  return (
    <Stack
      sx={{
        py: 12,
        maxWidth: 400,
        textAlign: 'center',
        m: 'auto',
        minHeight: '100vh',
        justifyContent: 'center',
      }}
    >
      <Page title="404">
        <MotionContainer>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Sorry, page not found!
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps
              you’ve mistyped the URL? Be sure to check your spelling.
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Image
              sx={{ my: { xs: 5, sm: 10 } }}
              src="/assets/icons/404.svg"
              alt="404"
            />
          </m.div>

          <Button
            component={RouterLink}
            to="/"
            size="large"
            variant="contained"
          >
            Go to Home
          </Button>
        </MotionContainer>
      </Page>
    </Stack>
  );
}
